import React, { useEffect } from "react";
import CommonBanner from "../components/CommonBanner";
import mainlogo from "../images/logo-min.png";
import foundersImage from "../images/founders-image.png";

import Aos from "aos";
import "aos/dist/aos.css";

function AboutUs() {
  // const mainlogo = "";
  const vision = "";
  useEffect(() => {
    Aos.init({ duration: 1200, offset: 120 });
  }, []);
  return (
    <div>
      <CommonBanner heading="About Us" />

      <div className="py-4 row mx-auto" style={{ backgroundColor: "black" }}>
        <div data-aos="fade-up" className="col col-lg-6 col-md-12 mx-auto my-auto" style={{ textAlign: "center" }}>
          <img src={mainlogo} loading="lazy" style={{ width: "85%" }} alt="" />
        </div>
        <div data-aos="fade-up" className="col col-lg-6 col-md-12 my-auto py-5" style={{ color: "white" }}>
          <h1 className="serv-header">Founder's Message</h1>
          <p className="serv-para">
            {" "}
            Welcome to Fairdeal Property – Your Gateway to Ideal Real Estate. Discover your perfect property with
            Fairdeal, a premier real estate company committed to meeting your unique requirements. Our mission is to
            deliver top-notch service, ensuring you find the high-quality property you've been searching for.
          </p>
        </div>
      </div>
      <div className="py-4 row mx-auto row2">
        <div data-aos="fade-up" className="col col-lg-6 col-md-12 my-auto">
          <h1 className="serv-header mt-2">Our Vision</h1>
          <p className="serv-para">
            {" "}
            At Fairdeal Property, we aspire to redefine the real estate experience, aiming to be recognized as a leading
            and trusted brand in the industry. Our commitment is to deliver unparalleled value and customer
            satisfaction, fostering enduring relationships that inspire loyalty and repeat business.
          </p>
        </div>
        <div data-aos="fade-up" className="col col-lg-6 col-md-12 mx-auto" style={{ textAlign: "center" }}>
          <img src={foundersImage} loading="lazy" style={{ width: "100%" }} alt="Founder's Image" />
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
