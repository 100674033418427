import React, { useEffect } from "react";
import CommonBanner from "../components/CommonBanner";
// import buy from "../images/buy-min().JPG";
// import sell from "../images/sell-min(1).JPG";
// import rent from "../images/rent-min(1).jpg";
// import lease from "../images/lease-min().JPG";
import "./ServicesPage.css";
import Aos from "aos";
import "aos/dist/aos.css";
import buy from "../images/buy.jpg";
import sell from "../images/sell.jpg";
import rent from "../images/rent.jpg";
import lease from "../images/lease.jpg";
function Services() {
  // const buy = "../images/buy.jpg";
  // const sell = "https://raeesrealty-static-images.s3.amazonaws.com/compressed+images/sell-min(1)-min.JPG";
  // const rent = "https://raeesrealty-static-images.s3.amazonaws.com/compressed+images/rent-min(1)-min.jpg";
  // const lease = "https://raeesrealty-static-images.s3.amazonaws.com/compressed+images/lease-min()-min.JPG";
  useEffect(() => {
    Aos.init({ duration: 1200 });
  }, []);
  return (
    <div>
      <CommonBanner heading="Services" />
      <div className="pt-2 pb-3" style={{ backgroundColor: "black" }}>
        <div className="container-fluid">
          <div className="row my-3 row2">
            <div data-aos="fade-up" className="col col-lg-7 col-md-12">
              <img src={buy} style={{ width: "100%", height: "100%" }} alt="" loading="lazy" />
            </div>
            <div className="col col-lg-5  col-md-12 my-auto">
              <div data-aos="fade-up" style={{ color: "white" }}>
                <h1 className="serv-header">Buy</h1>
                <p className="serv-para">
                  Uncover the property of your dreams with our assistance. We specialize in helping you find the perfect
                  home you've always envisioned.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="my-4">
        <div className="container-fluid">
          <div className="row my-3">
            <div className="col col-lg-5 col-md-12 my-auto">
              <div data-aos="fade-up">
                <h1 className="serv-header">Sell</h1>
                <p className="serv-para">
                  Entrust your property to us, and let our dedicated team secure the best rates for your sale. We're
                  here to ensure a seamless and successful selling process for you.
                </p>
              </div>
            </div>
            <div data-aos="fade-up" className="col col-lg-7 col-md-12">
              <img src={sell} style={{ width: "100%", height: "100%" }} alt="" loading="lazy" />
            </div>
          </div>
        </div>
      </div>
      <div className="pt-2 pb-3" style={{ backgroundColor: "black" }}>
        <div className="container-fluid">
          <div className="row my-3 row2">
            <div data-aos="fade-up" className="col col-lg-7 col-md-12">
              <img src={rent} style={{ width: "100%", height: "100%" }} alt="" loading="lazy" />
            </div>
            <div className="col col-lg-5  col-md-12 my-auto">
              <div data-aos="fade-up" style={{ color: "white" }}>
                <h1 className="serv-header">Rent</h1>
                <p className="serv-para">
                  Explore a diverse range of commercial, residential, and industrial properties for rent with ease.
                  Choose from our extensive options to find the perfect rental property that suits your needs
                  seamlessly.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="my-4">
        <div className="container-fluid">
          <div className="row my-3">
            <div className="col col-lg-5 col-md-12 my-auto">
              <div data-aos="fade-up">
                <h1 className="serv-header">Lease</h1>
                <p className="serv-para">
                  Secure the future of your commercial or industrial property by leasing it on a long-term basis with
                  us. Explore our leasing options to ensure a stable and beneficial arrangement for your property.
                </p>
              </div>
            </div>
            <div data-aos="fade-up" className="col col-lg-7 col-md-12">
              <img src={lease} style={{ width: "100%", height: "100%" }} alt="" loading="lazy" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
