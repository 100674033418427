import React from "react";
import ClientCarousel from "./ClientCarousel";
import "./ClientTestimonials.css";
function ClientTestimonials({ height }) {
  let newHeight = height;
  let mb = JSON.stringify(newHeight.height) + "px";
  let marginBottom = mb;
  // console.log(JSON.stringify(newHeight));
  // console.log(marginBottom);
  return (
    <div className="testimonials py-4" style={{ marginBottom }}>
      <div className="container">
        <h1
          className="mt-4"
          style={{ textAlign: "left", color: "white", fontWeight: "600" }}
        >
          Client Testimonials
        </h1>

        <ClientCarousel />
      </div>
    </div>
  );
}

export default ClientTestimonials;

//         <div className="testimonial-section wrapper">
//         <div className="container text-center">
//         <div className="text-center pb-4">
//             <h2 style={{textAlign:"left",color:"white",fontWeight:"600"}}>Client Testimonials</h2>
//             <div id="carouselExampleIndicators" className="carousel slide mt-5" data-bs-ride="carousel">
//   <div className="carousel-indicators">
//     <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
//     <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
//     <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
//   </div>
//   <div className="carousel-inner">
//     <div className="carousel-item active">
//     <div className="card py-3">

//   <div className="card-body">
//     <h5 className="card-title">John Doe</h5>
//     <p className="card-text">"I have found the best home i could have bought in my budget"</p>
//       </div>
// </div>
//     </div>
//     <div className="carousel-item">
//     <div className="card py-3">

//   <div className="card-body">
//     <h5 className="card-title">Special title treatment</h5>
//     <p className="card-text">With supporting text below as a natural lead-in to additional content.</p>

//   </div>
// </div>
//     </div>
//     <div className="carousel-item">
//     <div className="card py-3">

//   <div className="card-body">
//     <h5 className="card-title">Special title treatment</h5>
//     <p className="card-text">With supporting text below as a natural lead-in to additional content.</p>
//       </div>
// </div>
//     </div>
//   </div>
//   <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
//     <span className="carousel-control-prev-icon" aria-hidden="true"></span>
//     <span className="visually-hidden">Previous</span>
//   </button>
//   <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
//     <span className="carousel-control-next-icon" aria-hidden="true"></span>
//     <span className="visually-hidden">Next</span>
//   </button>
// </div>
//         </div>
//         </div>
//         </div>
