import React, { useEffect } from "react";
import CommonBanner from "../components/CommonBanner";
import Form from "../components/Form";
import "./ContactUs.css";
// import findus from "../images/findus-min().JPG";
// import contact from "../images/contact-min().jpg";
import contact from "../images/contact.jpg";
import findus from "../images/findus.jpg";
import Aos from "aos";
import "aos/dist/aos.css";

function ContactUs() {
  // const findus = "https://raeesrealty-static-images.s3.amazonaws.com/compressed+images/findus-min()-min.JPG";
  // const contact = "https://raeesrealty-static-images.s3.amazonaws.com/compressed+images/contact-min()-min.jpg";
  useEffect(() => {
    Aos.init({ duration: 1200, offset: 200 });
  }, []);
  return (
    <div>
      <CommonBanner heading="Contact Us" />
      <div className="py-3" style={{ backgroundColor: "black" }}>
        <div className="container-fluid">
          <div className="row my-3 pb-2">
            <div data-aos="fade-up" className="col col-lg-5  col-md-12 my-auto">
              <div className="row">
                <h1 className="pt-3 pb-4 text-center" style={{ fontWeight: "700", color: "#d4b470" }}>
                  You can Reach us at
                </h1>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                    flexDirection: "column",
                    color: "#d4b470",
                  }}
                >
                  <div
                    className="contact-fig my-3"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <i className="fas fa-map-marker-alt fa-3x my-4"></i>{" "}
                    <a
                      target="_blank"
                      className="contact-text my-2"
                      href="https://goo.gl/maps/GTeqPAPh4EHJHi998"
                      style={{ textDecoration: "none", color: "#d4b470" }}
                    >
                      Jogeshwari, Mumbai-102
                    </a>
                  </div>
                  <div
                    className="contact-fig my-3"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <i className="fas fa-phone-alt fa-3x my-4"></i>{" "}
                    {/* <h6 className="contact-text my-2">+91 9898989898</h6> */}
                    <h6 className="contact-text mt-2" style={{ color: "#d4b470" }}>
                      <a href="tel:+91 9870440447" style={{ textDecoration: "none", color: "#d4b470" }}>
                        {" "}
                        +91 98704 40447
                      </a>
                    </h6>
                    {/* <h6 className="contact-text mb-2" style={{color:"#e6d57f"}}><a href="tel:+91 9819000621" style={{textDecoration:"none",color:"#e6d57f"}}>+91 98190 00621</a></h6> */}
                  </div>
                  <div
                    className="contact-fig my-3"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <i className="far fa-envelope fa-3x my-4"></i>{" "}
                    {/* <h6 className="contact-text my-2">raeesrealty@gmail.com</h6> */}
                    <h6 className="contact-text" style={{ color: "#d4b470" }}>
                      <a
                        className="contact-text my-2"
                        href="mailto:fairdealsproperty4u@gmail.com"
                        style={{ textDecoration: "none", color: "#d4b470" }}
                      >
                        fairdealsproperty4u@gmail.com
                      </a>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div data-aos="fade-up" className="col col-lg-7 col-md-12">
              <img src={findus} style={{ width: "100%", height: "100%" }} alt="" loading="lazy" />
            </div>
          </div>
        </div>
      </div>
      <div className="my-4">
        <div className="container-fluid">
          <div data-aos="fade-up" className="row my-3 row2">
            <div className="col col-lg-7 col-md-12">
              <img src={contact} style={{ width: "100%", height: "100%" }} alt="" loading="lazy" />
            </div>

            <div data-aos="fade-up" className="col col-lg-5 col-md-12 my-auto">
              <h1 className="pt-3 pb-3 text-center" style={{ fontWeight: "700" }}>
                Get in Touch with us
              </h1>
              <Form />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div style={{ width: "100%", height: "60vh" }} className="mx-auto">
          <iframe
            width="100%"
            height="600"
            className="responsive-iframe-map"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d60308.58340328911!2d72.80883140311968!3d19.138933975166115!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b62dd1f4000f%3A0xbc3f4d74e370c8bf!2sFairdeal%20Property!5e0!3m2!1sen!2sin!4v1696148723347!5m2!1sen!2sin"
          >
            <a href="https://www.mapsdirections.info/en/measure-map-radius/">Radius distance map</a>
          </iframe>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
