import React from 'react'

function NotFound() {
    return (
        <div style={{height:"100vh", backgroundColor:"black", display:"flex",justifyContent:"center",alignItems:"center"}}>
        <h1 style={{color:"white",fontSize:"5rem"}}>404 Not Found</h1>
        </div>
    )
}

export default NotFound