import React, { useState, useEffect } from "react";
import "./Search.css";
import axios from "axios";

import Aos from "aos";
import "aos/dist/aos.css";

function Search(props) {
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);
  const [formData, setFormData] = useState({
    sale_type: "Any",
    price: "Rs 0+",
    property_type: "Any",
    sqft: "300+",
    bedrooms: "0+",
    bathrooms: "0+",
    keywords: "",
  });

  const { sale_type, price, property_type, sqft, bedrooms, bathrooms, keywords } = formData;

  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    props.setTitle("Search Results:");
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    props.setLoading(true);
    axios
      .post(
        `/api/property/search/`,
        {
          sale_type,
          price,
          property_type,
          sqft,
          bedrooms,
          bathrooms,
          keywords,
        },
        config
      )
      .then((res) => {
        props.setLoading(false);
        props.setListings(res.data);
        // console.log(props.listings);
      })
      .catch((err) => {
        props.setLoading(false);
        console.log("error");
      });
  };

  const resetForm = () => {
    setFormData({
      sale_type: "Any",
      price: "Rs 0+",
      property_type: "Any",
      sqft: "300+",
      bedrooms: "0+",
      bathrooms: "0+",
      keywords: "",
    });

    props.setTitle("All Properties");

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    props.setLoading(true);
    axios.get(`/api/property/`).then((res) => {
      props.setLoading(false);
      props.setListings(res.data);
    });
  };

  return (
    <div className="container">
      <div data-aos="fade-right" className="search-container py-4" style={{ backgroundColor: "#e9c45c" }}>
        <form onSubmit={(e) => onSubmit(e)}>
          <div className="row mx-3">
            <div className="col col-lg-4 col-md-12 my-2">
              <h5 style={{ color: "white", fontWeight: "500" }}>Sale Type</h5>
              <select
                className="form-select form-select-sm"
                aria-label="Default select example"
                style={{
                  color: "white",
                  backgroundColor: "#e9c45c",
                  border: "2px solid white",
                }}
                name="sale_type"
                onChange={(e) => onChange(e)}
                value={sale_type}
              >
                <option>Any</option>
                <option>For Sale</option>
                <option>For Rent</option>
                <option>For Lease</option>
              </select>
            </div>
            <div className="col col-lg-4 col-md-12 my-2">
              <h5 style={{ color: "white", fontWeight: "500" }}>Minimum price</h5>
              <select
                className="form-select form-select-sm"
                aria-label="Default select example"
                style={{
                  color: "white",
                  backgroundColor: "#e9c45c",
                  border: "2px solid white",
                }}
                name="price"
                onChange={(e) => onChange(e)}
                value={price}
              >
                <option>Rs 0+</option>
                <option>Rs 10,000+</option>
                <option>Rs 20,000+</option>
                <option>Rs 50,000+</option>
                <option>Rs 1,00,000+</option>
                <option>Rs 5,00,000+</option>
                <option>Rs 10,00,000+</option>
                <option>Rs 50,00,000+</option>
                <option>Rs 1,00,00,000+</option>
                <option>Rs 5,00,00,000+</option>
                <option>Rs 10,00,00,000+</option>
                <option>Rs 25,00,00,000+</option>
                <option>Rs 50,00,00,000+</option>
                <option>Any</option>
              </select>
            </div>
            <div className="col col-lg-4 col-md-12 my-2">
              <h5 style={{ color: "white" }}>Property Type</h5>
              <select
                className="form-select form-select-sm"
                aria-label="Default select example"
                style={{
                  color: "white",
                  backgroundColor: "#e9c45c",
                  border: "2px solid white",
                }}
                name="property_type"
                onChange={(e) => onChange(e)}
                value={property_type}
              >
                <option>Any</option>
                <option>Flat</option>
                <option>House</option>
                <option>Bungalow</option>
                <option>Villa</option>
                <option>Office</option>
              </select>
            </div>
          </div>
          <div className="row mx-3">
            <div className="col col-lg-4 col-md-12 my-2">
              <h5 style={{ color: "white", fontWeight: "500" }}>Area (sqft)</h5>
              <select
                className="form-select form-select-sm"
                aria-label="Default select example"
                style={{
                  color: "white",
                  backgroundColor: "#e9c45c",
                  border: "2px solid white",
                }}
                name="sqft"
                onChange={(e) => onChange(e)}
                value={sqft}
              >
                <option>300+</option>
                <option>500+</option>
                <option>1000+</option>
                <option>1200+</option>
                <option>1500+</option>
                <option>2000+</option>
                <option>Any</option>
              </select>
            </div>
            <div className="col col-lg-4 col-md-12 my-2">
              <h5 style={{ color: "white", fontWeight: "500" }}>Bedrooms</h5>
              <select
                className="form-select form-select-sm"
                aria-label="Default select example"
                style={{
                  color: "white",
                  backgroundColor: "#e9c45c",
                  border: "2px solid white",
                }}
                name="bedrooms"
                onChange={(e) => onChange(e)}
                value={bedrooms}
              >
                <option>0+</option>
                <option>1+</option>
                <option>2+</option>
                <option>3+</option>
                <option>4+</option>
                <option>5+</option>
              </select>
            </div>
            <div className="col col-lg-4 col-md-12 my-2 ">
              <h5 style={{ color: "white", fontWeight: "500" }}>Bathrooms</h5>
              <select
                className="form-select form-select-sm"
                aria-label="Default select example"
                style={{
                  color: "white",
                  backgroundColor: "#e9c45c",
                  border: "2px solid white",
                }}
                name="bathrooms"
                onChange={(e) => onChange(e)}
                value={bathrooms}
              >
                <option>0+</option>
                <option>1+</option>
                <option>2+</option>
                <option>3+</option>
                <option>4+</option>
              </select>
            </div>
          </div>
          <div className="row mx-3">
            <h5 className="mt-2" style={{ color: "white", fontWeight: "500" }}>
              Search
            </h5>
            <div className="col col-lg-8 col-md-12 mb-3 ">
              <input
                style={{
                  color: "white",
                  backgroundColor: "#e6d57f",
                  border: "2px solid white",
                }}
                className="form-control"
                placeholder="Type any keyword to search here.."
                name="keywords"
                type="text"
                onChange={(e) => onChange(e)}
                value={keywords}
              />
            </div>
            <div className="col col-lg-4 col-md-12 mb-3 d-flex gap-2">
              <button className="btn btn-light flex-grow-1" type="submit">
                Search
              </button>
              <button className="btn btn-light flex-grow-1" type="button" onClick={resetForm}>
                Reset
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Search;
