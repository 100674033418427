import React, { useEffect } from "react";
import { Link } from "react-router-dom";
// import collection from "../images/Untitleddesign(2).png";
import collection from "../images/collection.png";
import Aos from "aos";
import "aos/dist/aos.css";
function CollectionBody() {
  // const collection =
  //   "https://raeesrealty-static-images.s3.amazonaws.com/compressed+images/Untitleddesign(2)-min.png";
  useEffect(() => {
    Aos.init({ duration: 1200, offset: 80 });
  }, []);
  return (
    <div className="container">
      <div className="row">
        <div className="col col-lg-5 col-md-12 my-auto">
          <div data-aos="fade-up" className="my-4">
            <h1>We Make Everything Possible According To Your Requirements</h1>
            <p style={{ fontSize: "1.1rem" }}>
              Properties with top notch interiors which mesmerizes the visitor are also available with us
            </p>
            <div>
              <Link to="/services">
                <button className="btn btn-outline-warning" style={{ outline: "#ffc418" }}>
                  Know More
                </button>
              </Link>
            </div>
          </div>
        </div>
        <img data-aos="fade-up" className="col col-lg-7 col-md-12 my-3" src={collection} loading="lazy" alt="" />
      </div>
    </div>
  );
}

export default CollectionBody;
