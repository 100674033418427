import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import logo from "./logo.svg";
import "./App.css";
import Navbar from "./components/Navbar";
import Home from "./Pages/Home";
import ServicesPage from "./Pages/ServicesPage";
import Footer from "./components/Footer";
import AboutUs from "./Pages/AboutUs";
import Properties from "./Pages/Properties";
import ContactUs from "./Pages/ContactUs";
import PropertyDetails from "./Pages/PropertyDetails";
import NotFound from "./Pages/NotFound";
import ScrollToTop from "./components/ScrollToTop";
import FloatingActionButton from "./components/FloatingActionButton";

function App() {
  return (
    <div>
      <Router forceRefresh={true}>
        <Navbar />
        <ScrollToTop />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/services" component={ServicesPage} />
          <Route path="/aboutus" component={AboutUs} />
          <Route path="/properties" component={Properties} />
          <Route path="/contactus" component={ContactUs} />
          <Route path="/property/:id" component={PropertyDetails} />
          <Route component={NotFound} />
        </Switch>
        <FloatingActionButton />
        <Footer />
      </Router>
    </div>
  );
}

export default App;
