import React from "react";

function IFabs() {
  return (
    <button className="rtf--ab" style={{ backgroundColor: "#e91129" }}>
      <a
        style={{
          display: "block",
          textDecoration: "none",
          color: "white",
          margin: "auto",
          borderRadius: "50%",
          backgroundColor: "#e91129",
          border: "none",
          fontWeight: "500",
        }}
        href="https://www.instagram.com/fairdealsproperty/"
        target="_blank"
      >
        <i className="fab fa-instagram fa-lg fab-btn-link"></i>
      </a>
    </button>
  );
}

export default IFabs;
