import React from "react";

function YFabs() {
  return (
    <button className="rtf--ab" style={{ backgroundColor: "red" }}>
      <a
        style={{
          display: "block",
          textDecoration: "none",
          color: "white",
          margin: "auto",
          borderRadius: "50%",
          backgroundColor: "red",
          border: "none",
          fontWeight: "500",
        }}
        href="https://www.youtube.com/@fairdealspropertysuhel4247"
        target="_blank"
      >
        <i className="fab fa-youtube fa-lg"></i>
      </a>
    </button>
  );
}

export default YFabs;
