import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Form.css";
import ErrorAlert from "./ErrorAlert";
import SuccessAlert from "./SuccessAlert";
import Loading from "./Loading";
function Form() {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const { first_name, last_name, email, phone, subject, message } = formData;
  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    setLoading(true);
    axios
      .post(`/api/contact/`, { first_name, last_name, email, phone, subject, message }, config)
      .then((res) => {
        setLoading(false);
        // console.log(res);
        setSuccess(res.data.success);
        setFormData({
          first_name: "",
          last_name: "",
          email: "",
          phone: "",
          subject: "",
          message: "",
        });
      })
      .catch((err) => {
        setLoading(false);
        setError(err.response.data.error);
        console.log(err.response.data.error);
      });

    setTimeout(() => {
      setError("");
    }, 10000);
    setTimeout(() => {
      setSuccess("");
    }, 15000);
  };

  return (
    <div className="container mb-3">
      {loading && <Loading />}
      {error && <ErrorAlert message={error} />}
      {success && <SuccessAlert message={success} />}
      <form onSubmit={(e) => onSubmit(e)}>
        <div className="mb-2">
          <div className="row">
            <div className="col">
              <label for="firstname" className="form-label">
                First name
              </label>
              <input
                type="text"
                className="form-control"
                aria-label="First name"
                onChange={(e) => onChange(e)}
                name="first_name"
                value={first_name}
              />
            </div>
            <div className="col">
              <label for="lastname" className="form-label">
                Last name
              </label>
              <input
                type="text"
                className="form-control"
                aria-label="Last name"
                onChange={(e) => onChange(e)}
                name="last_name"
                value={last_name}
              />
            </div>
          </div>
        </div>
        <div className="mb-3">
          <label for="subject" className="form-label">
            Subject
          </label>
          <input
            type="text"
            className="form-control"
            id="subject"
            aria-describedby="emailHelp"
            onChange={(e) => onChange(e)}
            name="subject"
            value={subject}
          />
        </div>
        <div className="mb-3">
          <label for="exampleInputEmail1" className="form-label">
            Email address
          </label>
          <input
            type="email"
            className="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            onChange={(e) => onChange(e)}
            name="email"
            value={email}
          />
          <div id="emailHelp" className="form-text">
            We'll never share your email with anyone else.
          </div>
        </div>
        <div className="mb-3">
          <label for="phone" className="form-label">
            Phone
          </label>
          <input
            type="tel"
            className="form-control"
            id="phone"
            aria-describedby="phoneHelp"
            onChange={(e) => onChange(e)}
            name="phone"
            value={phone}
          />
          <div id="phoneHelp" className="form-text">
            We'll never share your phone with anyone else.
          </div>
        </div>
        <div className="mb-3">
          <label for="exampleInputPassword1" className="form-label">
            How can we help you?
          </label>
          <textarea
            type="text-area"
            className="form-control"
            id="exampleInputPassword1"
            onChange={(e) => onChange(e)}
            name="message"
            value={message}
          />
        </div>
        <button type="submit" className="btn btn-primary px-3 py-2 form-btn" style={{ backgroundColor: "#d4b470" }}>
          Send Message
        </button>
      </form>
    </div>
  );
}

export default Form;
