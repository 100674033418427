import React from "react";

function CFabs() {
  return (
    <button className="rtf--ab" style={{ backgroundColor: "#800000" }}>
      <a
        style={{
          display: "block",
          textDecoration: "none",
          color: "white",
          margin: "auto",
          borderRadius: "50%",
          backgroundColor: "#800000",
          border: "none",
          fontWeight: "500",
        }}
        href="tel:+91 9870440447"
      >
        <i className="fas fa-phone-alt fa-lg"></i>
      </a>
    </button>
  );
}

export default CFabs;
