import React from "react";

function WFabs() {
  return (
    <div>
      <button className="rtf--ab" style={{ backgroundColor: "#4dcb5b" }}>
        <a
          style={{
            display: "block",
            textDecoration: "none",
            color: "white",
            margin: "auto",
            borderRadius: "50%",
            backgroundColor: "#4dcb5b",
            border: "none",
            width: "100%",
            fontWeight: "500",
          }}
          href="https://wa.me/919870440447"
          target="_blank"
        >
          <i className="fab fa-whatsapp fa-lg"></i>
        </a>
      </button>
    </div>
  );
}

export default WFabs;
