import React from 'react'
import CollectionBody from './CollectionBody'

function OurCollection() {
    return (
        <div className=" py-5" style={{backgroundColor:"white"}}>
            {/* <h1 className="recent-title mt-4 mb-3">Our <span style={{color:"#ffc418"}}> Collection</span></h1> */}
            <CollectionBody />
        </div>
    )
}

export default OurCollection
