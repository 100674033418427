import React,{useEffect} from 'react'
import ServicesCards from './ServicesCards'
import Aos from 'aos'
import "aos/dist/aos.css"

function Services() {

    useEffect(() => {
        Aos.init({duration:1200,offset: 200,})
     }, [])
    return (
        <div className="services-container pt-3">
        <div className="container pt-4 pb-5">
            <h1 data-aos="fade-right" className="recent-title" style={{color:"white",fontWeight:"600"}}>Services We Offer</h1>
            <ServicesCards  />
        </div>
        </div>
    )
}

export default Services
