import React from "react";
import { Link } from "react-router-dom";
import "./card.css";

function Card({ property }) {
  return (
    <div className="container my-2 rounded">
      <div className="card property-card">
        <Link to={`/property/${property.slug}`} className="card-link">
          <div className="iframe-container">
            <iframe
              src={property.youtube_link}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="responsive-iframe"
            />
          </div>
          <div className="card-body">
            <h5 className="card-title">{property.name}</h5>
            <div className="property-info">
              <div className="property-location">
                <i className="fas fa-map-marker-alt"></i>
                <span>{property.landmark}</span>
              </div>
              <div className="property-details">
                <i className="fas fa-bed"></i>
                <span className="me-2">{property.bedrooms}</span>
                <i className="fas fa-bath"></i>
                <span>{property.bathrooms}</span>
              </div>
            </div>
            <h5 className="price">Price: {property.price}</h5>
            <h5 className="sale-type">{property.sale_type}</h5>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default Card;
