import React from "react";
import { Fab, Action } from "react-tiny-fab";
import "react-tiny-fab/dist/styles.css";
import YFabs from "./YFabs";
import CFabs from "./CFabs";
import C2Fabs from "./C2Fabs";
import FabIcon from "./FabIcon";
import WFabs from "./WFabs";
import IFabs from "./IFabs";
function FloatingActionButton() {
  return (
    <Fab
      mainButtonStyles={{ backgroundColor: "#444444" }}
      icon={<FabIcon />}
      event="click"
    >
      <CFabs />
      <C2Fabs />
      <WFabs />
      <IFabs />
      <YFabs />
    </Fab>
  );
}

export default FloatingActionButton;
