import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./OwlCar.css";
import properties from "../properties";
import Card from "./Card";
import axios from "axios";

const options = {
  margin: 20,
  responsiveClass: true,
  nav: true,
  loop: true,
  autoplay: true,
  autoplayTimeout: 2500,
  autoplayHoverPause: true,
  navText: ["<div>‹</div>", "<div>›</div>"],
  smartSpeed: 1000,
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 1.2,
    },
    550: {
      items: 1.5,
    },
    600: {
      items: 1.8,
    },
    700: {
      items: 1.8,
    },
    750: {
      items: 2,
    },
    1000: {
      items: 3,
    },
    1264: {
      items: 4,
    },
    1300: {
      items: 4,
    },
  },
};

function OwlCar() {
  const [recentlistings, setRecentlistings] = useState([]);
  useEffect(() => {
    async function fetchRecentProperties() {
      const { data } = await axios.get(`/api/property/recent/`);
      // console.log(data.results);
      const listings = data;
      setRecentlistings(listings);
      // console.log(recent);
    }
    fetchRecentProperties();
  }, []);

  return (
    <div className="container-fluid">
      <OwlCarousel
        className="slider-items owl-carousel owl-theme"
        {...options}
        key={`carousel_${recentlistings.length}`}
      >
        {recentlistings.map((property) => (
          <div className="item text-center" key={property.id}>
            <Card property={property} />
          </div>
        ))}
      </OwlCarousel>
    </div>
  );
}

export default OwlCar;

//   <div>
//       <div className='container-fluid' >
//       <div className="row title" style={{marginBottom: "20px"}} >
//       </div>
//   </div>
//   <div className='container' >
//     <OwlCarousel items={3} margin={8} autoplay ={true} >
//   <div ><div className="row">
// {properties.map(property => (
//   <div key={property.id} className="col col-xl-3 col-lg-4 col-md-6 col-sm-12">
//       <Card property={property} />
//   </div>
// ))}
// </div></div>
// </OwlCarousel>
// </div>
// </div>
