import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import mainlogo from "../images/logo-min.png";

function Footer() {
  // const [width, setWidth] = useState(window.innerWidth);
  // const ref = useRef(null)
  // const updateWidth = () => {
  //   setWidth(window.innerWidth);
  // };
  // useEffect(() => {
  //   setHeight(ref.current.clientHeight)
  //   // console.log(ref.current.clientHeight);
  //   window.addEventListener("resize", updateWidth);
  //   return () => window.removeEventListener("resize", updateWidth);
  // })
  // const mainlogo = "";
  return (
    <div style={{ backgroundColor: "black", width: "100%" }} className="py-2">
      <div className="container py-4" style={{ color: "white" }}>
        <div className="footer-items row">
          <div className="logo my-3 col col-lg-4 col-md-6 col-mdf-6">
            {/* <h2>Fairdeal Property(logo)</h2> */}
            <div className="px-5">
              <img src={mainlogo} className="fluid" style={{ width: "100%", marginBottom: "4px" }} alt="" />
              <h6 className="my-2" style={{ color: "#d4b470" }}>
                <a href="mailto:fairdealsproperty4u@gmail.com" style={{ textDecoration: "none", color: "#d4b470" }}>
                  Email: <span className="footer-links">fairdealsproperty4u@gmail.com</span>
                </a>
              </h6>
              <h6 style={{ color: "#d4b470" }}>
                <a href="tel:+91 9870440447" style={{ textDecoration: "none", color: "#d4b470" }}>
                  Phone: <span className="footer-links">+91 98704 40447</span>
                </a>
              </h6>
              <h6 className="footer-links" style={{ color: "#d4b470" }}>
                <a href="tel:+91 9876862858" style={{ textDecoration: "none", color: "#d4b470" }}>
                  <span style={{ visibility: "hidden" }}>Phone:</span> +91 98768 62858
                </a>
              </h6>
            </div>
          </div>
          <div className="navigation-links my-3 col col-lg-4 col-md-6 col-mdf-6">
            <div className="px-5">
              <h3>Navigation Links</h3>
              <Link className="f-navlinks" to="/">
                Home
              </Link>{" "}
              <br />
              <Link className="f-navlinks" to="/services">
                Services
              </Link>{" "}
              <br />
              <Link className="f-navlinks" to="/aboutus">
                About us
              </Link>{" "}
              <br />
              <Link className="f-navlinks" to="/properties">
                Properties
              </Link>{" "}
              <br />
              <Link className="f-navlinks" to="/contactus">
                Contact Us
              </Link>{" "}
              <br />
            </div>
            {/* <a href="" className="f-navlinks" style={{color:"grey"}}>Home<br /></a>  
    <a href=""  className="f-navlinks" style={{color:"grey"}}>Services<br /></a>        */}
            {/* <a href=""  className="f-navlinks" style={{color:"grey"}}>About us<br /></a>       
        <a href="" className="f-navlinks" style={{color:"grey"}}>Properties <br /></a>   
      <a href=""  className="f-navlinks" style={{color:"grey"}}>Contact Us<br /></a> */}
          </div>
          <div className="social my-3 col col-lg-4 col-md-6 col-mdf-6">
            <div className="px-5">
              <h3>Follow us</h3>
              <div style={{ color: "grey" }}>
                {/* <a className="social-media-links" href="https://www.instagram.com/abrarteli/?hl=enA" target="_blank">
                  {" "}
                  <i className="fab fa-instagram"> Instagram @abrarteli</i>
                  <br />{" "}
                </a> */}
                <a className="social-media-links" href="https://www.instagram.com/fairdealsproperty/" target="_blank">
                  {" "}
                  <i className="fab fa-instagram"> Instagram @FairdealProperty</i>
                  <br />{" "}
                </a>
                <a
                  className="social-media-links"
                  href="https://www.youtube.com/@fairdealspropertysuhel4247"
                  target="_blank"
                >
                  {" "}
                  <i className="fab fa-youtube"> Youtube @FairdealProperty</i>
                  <br />{" "}
                </a>
                {/* <a
                  className="social-media-links"
                  href="https://www.linkedin.com/in/abrar-teli-347660131/?originalSubdomain=in"
                  target="_blank"
                >
                  {" "}
                  <i className="fab fa-linkedin"> Linkedin @Abrar Teli</i>
                  <br />{" "}
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer style={{ color: "white" }} className="mt-2">
        Copyright &copy; {new Date().getFullYear()} Fairdeal Property
      </footer>
    </div>
  );
}

export default Footer;
