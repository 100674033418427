// HeroSection.js
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./HeroSection.css";

import Aos from "aos";
import "aos/dist/aos.css";

function HeroSection() {
  useEffect(() => {
    Aos.init({ duration: 1200 });
  }, []);

  return (
    <div className="hero-section">
      <div data-aos="fade-up" className="container hero-content">
        <div>
          <h1 className="hero-title mt-3">
            Your Home, Our Passion. <br /> Let's <span className="accent-color">Find It Together</span>
          </h1>
        </div>
        <div>
          <p className="hero-para">
            Discover your dream home with Fairdeal Property,
            <br /> your trusted real estate solution firm.
          </p>
        </div>
        <div>
          <Link to="/properties">
            <button className="btn hero-btn">
              Find My Property <i className="fas fa-arrow-right"></i>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
