import React, { useState, useEffect } from "react";
import CommonBanner from "../components/CommonBanner";
import Search from "../components/Search";
import properties from "../properties";
import Card from "../components/Card";
import Pagination from "../components/Pagination";

import axios from "axios";
import PropertiesLoading from "../components/PropertiesLoading";
function Properties() {
  const [listings, setListings] = useState([]);
  const [sortType, setSortType] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [listingsPerPage] = useState(10);

  const [title, setTitle] = useState("All Properties");

  const [loading, setLoading] = useState(false);

  const indexOfLastListing = currentPage * listingsPerPage;
  const indexOfFirstListing = indexOfLastListing - listingsPerPage;
  const currentListings = listings.slice(indexOfFirstListing, indexOfLastListing);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    setLoading(true);
    async function fetchProperties() {
      const { data } = await axios.get(`/api/property/`);
      // console.log(data.results);
      setLoading(false);
      setListings(data);
      // console.log(recent);
    }
    fetchProperties();
  }, []);

  useEffect(() => {
    const sortArray = (type) => {
      const sortProperty = type;
      console.log(sortProperty);
      if (sortProperty == "created_at") {
        const sorted = [...listings].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        // console.log(sorted);
        setListings(sorted);
      }
      if (sortProperty == "rev-created_at") {
        const sorted = [...listings].sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
        // console.log(sorted);
        setListings(sorted);
      }
      if (sortProperty == "price") {
        const sorted = [...listings].sort((a, b) => b["price_in_figures"] - a["price_in_figures"]);
        console.log(sorted);
        setListings(sorted);
      }
      if (sortProperty == "rev-price") {
        const sorted = [...listings].sort((a, b) => b["price_in_figures"] - a["price_in_figures"]);
        setListings(sorted.reverse());
      }
    };
    sortArray(sortType);
  }, [sortType]);

  return (
    <div>
      <CommonBanner heading="Find Properties" />
      <Search listings={listings} setListings={setListings} setTitle={setTitle} setLoading={setLoading} />
      <div className="container">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2 className="my-3">{title}</h2>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h6 className="mx-2 my-auto">SORT BY </h6>
            <form style={{ display: "inline" }}>
              <select
                style={{ width: "25vw" }}
                class="form-select my-auto"
                aria-label="Default select example"
                onChange={(e) => setSortType(e.target.value)}
              >
                <option selected value="created_at">
                  Published newest
                </option>
                <option value="rev-created_at">Published oldest</option>
                <option value="price">Price Highest</option>
                <option value="rev-price">Price Lowest</option>
              </select>
            </form>
          </div>
        </div>
        {loading && <PropertiesLoading />}
        <div className="row">
          {currentListings != "" ? (
            currentListings.map((property) => (
              // <div className="item text-center col col-xl-3 col-lg-4 col-md-6 my-2" key={property.id}>
              //   <Card property={property} />
              // </div>
              <div className="item text-center col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 my-2" key={property.id}>
                <Card property={property} />
              </div>
            ))
          ) : (
            <div className="container">
              <h5 className="mx-auto text-center my-5">NO SEARCH RESULTS</h5>
            </div>
          )}
        </div>
        {/* {property} */}
        <div className="pagination">
          <Pagination listingsPerPage={listingsPerPage} totalListings={listings.length} paginate={paginate} />
        </div>
      </div>
    </div>
  );
}

export default Properties;
