import React from "react";
import "./CommonBanner.css";

function CommonBanner({ heading }) {
  return (
    <div className="banner">
      <div className="container">
        <h1 className="banner-title">{heading}</h1>
      </div>
    </div>
  );
}

export default CommonBanner;
