import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./ServicesCards.css";

import Aos from "aos";
import "aos/dist/aos.css";

function ServicesCards() {
  useEffect(() => {
    Aos.init({ duration: 1200, offset: 120 });
  }, []);
  return (
    <div data-aos="fade-up" className="container row my-5 pt-4 mx-auto">
      <div
        className="card services-card col col-xl-3 col-lg-4 col-md-6 col-sm-12 mx-auto my-2"
        style={{ width: "18rem" }}
      >
        <Link to="/services">
          <div className="card_overlay card_overlay--primary">
            <div className="overlay_title">Know More </div>
          </div>
        </Link>
        <div className="card-body">
          <div className="text-center mx-2">
            <div className="serv-card-icon text-center mt-4 mb-3" style={{ display: "flex" }}>
              <i
                className="fontawesome-style fas fa-money-bill-wave fa-2x mx-auto my-auto"
                style={{ justifyContent: "center", alignItems: "center" }}
              ></i>
            </div>
          </div>
          <h4 className="card-title text-center" style={{ fontWeight: "600" }}>
            Buy
          </h4>
          <p className="card-text my-4" style={{ textAlign: "center" }}>
            Buy your property with us.
          </p>
        </div>
      </div>
      <div
        className="card services-card col col-xl-3 col-lg-4 col-md-6 col-sm-12 mx-auto my-2"
        style={{ width: "18rem" }}
      >
        <Link to="/services">
          <div className="card_overlay card_overlay--primary">
            <div className="overlay_title">Know More </div>
          </div>
        </Link>
        <div className="card-body">
          <div className="text-center mx-2">
            <div className="serv-card-icon text-center mt-4 mb-3" style={{ display: "flex" }}>
              <i
                className="fontawesome-style fas fa-handshake fa-2x mx-auto my-auto"
                style={{ justifyContent: "center", alignItems: "center" }}
              ></i>
            </div>
          </div>
          <h4 className="card-title text-center" style={{ fontWeight: "600" }}>
            Sell
          </h4>
          <p className="card-text my-4" style={{ textAlign: "center" }}>
            Sell your property with us.
          </p>
        </div>
      </div>
      <div
        className="card services-card col col-xl-3 col-lg-4 col-md-6 col-sm-12 mx-auto my-2"
        style={{ width: "18rem" }}
      >
        <Link to="/services">
          <div className="card_overlay card_overlay--primary">
            <div className="overlay_title">Know More </div>
          </div>
        </Link>
        <div className="card-body">
          <div className="text-center mx-2">
            <div className="serv-card-icon text-center mt-4 mb-3" style={{ display: "flex" }}>
              <i
                className="fontawesome-style fas fa-home fa-2x mx-auto my-auto"
                style={{ justifyContent: "center", alignItems: "center" }}
              ></i>
            </div>
          </div>
          <h4 className="card-title text-center" style={{ fontWeight: "600" }}>
            Rent
          </h4>
          <p className="card-text my-4" style={{ textAlign: "center" }}>
            Rent your property with us.
          </p>
        </div>
      </div>
      <div
        className="card services-card col col-xl-3 col-lg-4 col-md-6 col-sm-12 mx-auto my-2"
        style={{ width: "18rem" }}
      >
        <Link to="/services">
          <div className="card_overlay card_overlay--primary">
            <div className="overlay_title">Know More </div>
          </div>
        </Link>
        <div className="card-body">
          <div className="text-center mx-2">
            <div className="serv-card-icon text-center mt-4 mb-3" style={{ display: "flex" }}>
              <i
                className="fontawesome-style fas fa-archway fa-2x mx-auto my-auto"
                style={{ justifyContent: "center", alignItems: "center" }}
              ></i>
            </div>
          </div>
          <h4 className="card-title text-center" style={{ fontWeight: "600" }}>
            Lease
          </h4>
          <p className="card-text my-4" style={{ textAlign: "center" }}>
            Lease your Property with us
          </p>
        </div>
      </div>
    </div>
  );
}

export default ServicesCards;

//     <div className="services">
//   <div className="services-container">
//     <div className="services-item">
//     <i className="fontawesome-style fas fa-money-bill-wave fa-2x"></i>
//       <h2 className="sub-heading">Buying</h2>
//       <p>
//         Buy a Property with us
//       </p>
//     </div>
//     <div className="services-item">
//     <i className="fontawesome-style fas fa-handshake fa-2x"></i>
//       <h2 className="sub-heading">Selling</h2>
//       <p>
//         Sell your Property with us
//       </p>
//     </div>
//     <div className="services-item">
//     <i className="fontawesome-style fas fa-home fa-2x"></i>
//       <h2 className="sub-heading">Rent</h2>
//       <p>
//         Rent a Property with us
//       </p>
//     </div>
//   </div>
// </div>
