import React from "react";
import { Link } from "react-router-dom";
import Card from "./Card";
import OwlCar from "./OwlCar";
import "./RecentListingSection.css";
import RecentProperties from "./RecentProperties";
import axios from "axios";

function RecentListingSection(data) {
  return (
    <div style={{ backgroundColor: "white" }}>
      <div className="container">
        <h1 className="recent-title pt-5" style={{ fontWeight: "600" }}>
          Our <span> Recent Listings</span>
        </h1>
        <Link to="/properties" className="link-to-search">
          <h4 className="link-to-search my-4" style={{ textAlign: "right" }}>
            Find More Listings <i className="fas fa-arrow-right"></i>
          </h4>
        </Link>
        <OwlCar />
      </div>
    </div>
  );
}

export default RecentListingSection;
