import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import CommonBanner from "../components/CommonBanner";
import properties from "../properties";
import "./PropertyDetails.css";
import axios from "axios";

function PropertyDetails({ match }) {
  const slug = match.params.id;
  const [property, setProperty] = useState([]);
  let history = useHistory();

  useEffect(() => {
    async function fetchProperty() {
      const { data } = await axios.get(`/api/property/${slug}`);
      // console.log(data);
      setProperty(data);
      // console.log(recent);
    }
    fetchProperty();
  }, []);

  return (
    <div>
      <CommonBanner heading={property.name} />
      <div className="container">
        {/* <Link to="/" className="btn btn-light my-4 px-3 py-2">
                    Go Back
                </Link> */}
        <button className="btn btn-light my-4 px-3 py-2" onClick={() => history.goBack()}>
          Go Back
        </button>
        <div className="row">
          <div className="col col-xl-12 mb-4">
            <div className="video-container">
              <iframe
                src={property.youtube_link}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; fullscreen; picture-in-picture"
                allowfullscreen
                style={{ margin: "auto" }}
                className=" responsive-iframe"
              />
            </div>
          </div>
          <div className="col col-xl-6 col-md-12 mb-4">
            <div
              className="list-group"
              style={{
                border: "none !important",
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              }}
            >
              <div className="list-group-item">
                <div className="row">
                  <div className="col" style={{ fontWeight: "500" }}>
                    Name:
                  </div>
                  <div className="col" style={{ fontWeight: "800" }}>
                    <h5>{property.name}</h5>
                  </div>
                </div>
              </div>
              <div className="list-group-item">
                <div className="row">
                  <div className="col" style={{ fontWeight: "500" }}>
                    Landmark:
                  </div>
                  <div className="col">{property.landmark}</div>
                  <div className="col" style={{ fontWeight: "500" }}>
                    Price:
                  </div>
                  <div className="col">{property.price}</div>
                </div>
              </div>
              <div className="list-group-item">
                <div className="row">
                  <div className="col" style={{ fontWeight: "500" }}>
                    Property Type:
                  </div>
                  <div className="col">{property.property_type}</div>
                  <div className="col" style={{ fontWeight: "500" }}>
                    Sale Type:
                  </div>
                  <div className="col">{property.sale_type}</div>
                </div>
              </div>
              <div className="list-group-item">
                <div className="row">
                  <div className="col" style={{ fontWeight: "500" }}>
                    Bedrooms:
                  </div>
                  <div className="col">{property.bedrooms}</div>
                  <div className="col" style={{ fontWeight: "500" }}>
                    Bathrooms:
                  </div>
                  <div className="col">{property.bathrooms}</div>
                </div>
              </div>
              <div className="list-group-item">
                <div className="row">
                  <div className="col" style={{ fontWeight: "500" }}>
                    Total Floors:
                  </div>
                  <div className="col">{property.total_floors}</div>
                  <div className="col" style={{ fontWeight: "500" }}>
                    Floor no:
                  </div>
                  <div className="col">{property.floor_no}</div>
                </div>
              </div>
              <div className="list-group-item">
                <div className="row">
                  <div className="col" style={{ fontWeight: "500" }}>
                    Area:
                  </div>
                  <div className="col">{property.sqft}</div>
                  <div className="col" style={{ fontWeight: "500" }}>
                    Furnished status:
                  </div>
                  <div className="col">{property.furnished_status}</div>
                </div>
              </div>
              <div className="list-group-item">
                <div className="row">
                  <div className="col" style={{ fontWeight: "500" }}>
                    Car Parking:
                  </div>
                  <div className="col">{property.car_parking}</div>
                  <div className="col" style={{ fontWeight: "500" }}>
                    Maintenance cost:
                  </div>
                  <div className="col">{property.maintenance_cost}</div>
                </div>
              </div>
              <div className="list-group-item">
                <div className="row">
                  <div className="col" style={{ fontWeight: "500" }}>
                    Amenities:
                  </div>
                  <div className="col">{property.amenities}</div>
                </div>
              </div>
              <div className="list-group-item">
                <div className="row">
                  <div className="col" style={{ fontWeight: "500" }}>
                    Description:
                  </div>
                  <div className="col">{property.description}</div>
                </div>
              </div>
            </div>
          </div>

          <div className="col col-xl-6 mb-4 property-map">
            <iframe
              width="100%"
              height="600"
              className="responsive-iframe-map"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              src={property.googlemaps_link}
            >
              <a href="https://www.mapsdirections.info/en/measure-map-radius/">Radius distance map</a>
            </iframe>
          </div>
        </div>
        <div
          className="px-3 py-2 mb-4"
          style={{
            border: "none !important",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          }}
        >
          <h3 style={{ fontWeight: "500" }} className="my-2">
            Interested in this one or want to know more?{" "}
            <Link to="/contactus" style={{ textDecoration: "none" }}>
              <span style={{ color: "#ffc418" }}> Contact us</span>
            </Link>{" "}
          </h3>
        </div>
      </div>
    </div>
  );
}

export default PropertyDetails;
//"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.3154975642465!2d72.83265021485222!3d19.137662187052502!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b624ba7a0c11%3A0x644807ff9ed32af1!2sTranscon%20Triumph%2C%20Veera%20Desai%20Industrial%20Estate%2C%20Andheri%20West%2C%20Mumbai%2C%20Maharashtra%20400047!5e0!3m2!1sen!2sin!4v1636959139580!5m2!1sen!2sin"
