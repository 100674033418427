import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import mainlogonobg from "../images/Logo-1.png";

function Navbar() {
  const [navbar, setNavbar] = useState(false);
  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", changeBackground);
  //"navbar navbar-expand-lg navbar-dark bg-dark fixed-top activate"
  return (
    <div>
      <nav
        className={
          navbar
            ? "navbar navbar-expand-lg navbar-dark bg-dark fixed-top activate"
            : "navbar navbar-expand-lg navbar-dark bg-dark fixed-top"
        }
      >
        <div className="container">
          <Link to="/" className="navbar-brand" style={{ width: "30%" }}>
            <img src={mainlogonobg} className="rr-logo" alt="" />
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasExample"
            aria-controls="offcanvasExample"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="fas fa-stream navbar-toggle-icon"></i>
            {/* <span className="navbar-toggler-icon"></span> */}
          </button>
          <div
            className="offcanvas offcanvas-start-lg"
            tabindex="-1"
            id="offcanvasExample"
            aria-labelledby="offcanvasExampleLabel"
          >
            <div className="offcanvas-header d-flex d-lg-none">
              <img
                src={mainlogonobg}
                style={{ width: "30%" }}
                className="offcanvas-title text-white"
                id="offcanvasExampleLabel"
              />
              <a href="javascript:void(0) " className="text-reset p-0" data-bs-dismiss="offcanvas" aria-label="close">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="#FFFFFF"
                  className="bi bi-x-lg"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
                  />
                </svg>
              </a>
            </div>
            <div className="offcanvas-body p-lg-0" id="navbarNav">
              <ul className="navbar-nav ms-auto">
                <li className="nav-item mx-3" data-bs-dismiss="offcanvas" aria-label="close">
                  <Link to="/" className="nav-link active" aria-current="page">
                    Home
                  </Link>
                </li>
                <li className="nav-item mx-3" data-bs-dismiss="offcanvas" aria-label="close">
                  <Link to="/services" className="nav-link" aria-current="page">
                    Services
                  </Link>
                </li>
                <li className="nav-item mx-3" data-bs-dismiss="offcanvas" aria-label="close">
                  <Link to="/aboutus" className="nav-link" aria-current="page">
                    About Us
                  </Link>
                </li>
                <li className="nav-item mx-3" data-bs-dismiss="offcanvas" aria-label="close">
                  <Link to="/properties" className="nav-link" aria-current="page">
                    Properties
                  </Link>
                </li>
                <li className="nav-item mx-3" data-bs-dismiss="offcanvas" aria-label="close">
                  <Link to="/contactus" className="nav-link" aria-current="page">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
