import React from 'react'

function PropertiesLoading() {
    return (
        <div>
         <div class="spinner-border mx-auto"  style={{
                height: '100px',
                width: '100px',
                margin: 'auto',
                display: 'block'
            }} role="status">
  <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    )
}

export default PropertiesLoading
