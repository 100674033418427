import React, { useEffect, useState } from 'react'
import ClientTestimonials from '../components/ClientTestimonials'
import HeroSection from '../components/HeroSection'
import OurCollection from '../components/OurCollection'
import RecentListingSection from '../components/RecentListingSection'
import Services from '../components/Services'
import axios from 'axios'

function Home() {
    return (
        <div>
            <HeroSection />
            <RecentListingSection />
            <Services />
            <OurCollection />
            {/* <ClientTestimonials height={props} /> */}
        </div>
    )
}

export default Home
